<template>
  <div>
    <v-row justify="center" class="mt-5">
      <v-col cols="12">
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn icon :to="`/team/${$route.params.team}`" exact>
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <h2 class="white--text">Team bearbeiten</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10" class="px-2 py-0">
        <v-card :color="template.colors.blocks" rounded="xl">
          <v-row justify="center">
            <v-col cols="11">
              <v-text-field
                v-model="teamname"
                label="Teamname"
                rounded
                filled
                light
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-btn
                large
                block
                class="rounded-b-xl"
                :color="template.colors.primary"
                :loading="changing"
                @click="changeTeamname()"
              >
                <v-icon class="mr-2">mdi-content-save</v-icon>
                Teamname ändern
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="10" class="px-2 py-5" v-if="user.data.abteilungen">
        <v-card :color="template.colors.blocks" rounded="xl">
          <v-row justify="center">
            <v-col cols="11">
              <v-select
                v-model="abteilung"
                :items="user.data.abteilungen"
                item-text="name"
                item-value="name"
                label="Abteilung"
                rounded
                filled
                light
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-btn
                large
                block
                class="rounded-b-xl"
                :color="template.colors.primary"
                :loading="saving"
                @click="updateAbteilung()"
              >
                <v-icon class="mr-2">mdi-content-save</v-icon>
                Abteilung Speichern
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="10" class="px-2 py-5">
        <v-card
          :color="template.colors.blocks"
          :light="!template.isDark(template.colors.blocks)"
          :dark="template.isDark(template.colors.blocks)"
          rounded="xl"
        >
          <v-row justify="center">
            <v-col cols="11">
              <v-switch
                v-model="ggg_regel"
                @change="update_ggg()"
                :light="!template.isDark(template.colors.blocks)"
                :dark="template.isDark(template.colors.blocks)"
                label="Alle Teammitglieder sind jünger als 16 Jahre"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-divider class="my-4"></v-divider>
        <v-checkbox
          v-if="start_delete"
          :disabled="deleting"
          v-model="del_veranstaltungen"
          :color="template.colors.inline_primary_text"
          label="Alle Veranstaltungen dieses Teams unwiderruflich löschen."
        ></v-checkbox>
        <cardbutton
          :title="start_delete ? 'Entfernen bestätigen' : 'Team entfernen'"
          icon="mdi-account-multiple-remove"
          :textcolor="template.colors.block_text"
          :bgcolor="template.colors.blocks"
          upper
          :loading="deleting"
          @click="start_delete ? deleteTeam() : del()"
        />
      </v-col>
      <v-col cols="10" class="px-2 py-0" v-if="start_delete">
        <cardbutton
          title="Entfernen abbrechen"
          :textcolor="template.colors.blocks"
          :bgcolor="template.colors.block_text"
          upper
          :disabled="deleting"
          @click="start_delete = false"
        />
      </v-col>
    </v-row>
    <v-snackbar v-model="alert" :color="alertcolor" top centered multi-line>
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import cardbutton from '../../../elements/cards/card-button'
import { mapGetters } from 'vuex'
import store from '../../../store'
import router from '../../../routes/index'

export default {
  name: 'mitglied-bearbeiten',
  data() {
    return {
      teamname: '',
      abteilung: '',
      start_delete: false,
      alert: false,
      alertcolor: '',
      text: '',
      deleting: false,
      changing: false,
      saving: false,
      ggg_regel: false,
      del_veranstaltungen: false,
    }
  },
  mounted() {
    if (this.$route.params.team) {
      if (this.team.id != this.$route.params.team) {
        store.dispatch('setTeam', this.$route.params.team)
      }
      this.teamname = this.team.data.name
      this.abteilung = this.team.data.abteilung
      this.ggg_regel = this.team.data.ggg_regel || false
    } else {
      store.dispatch('closeTeam')
    }
  },
  components: {
    cardbutton,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      team: 'team',
      template: 'template',
    }),
  },
  methods: {
    update_ggg() {
      this.saving = true
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.team.id)
        .update({
          ggg_regel: this.ggg_regel,
        })
        .then((doc) => {
          this.alert = true
          this.alertcolor = 'success'
          this.text = 'Team wurde erfolgreich gespeichert!'
          this.saving = false
        })
        .catch((error) => {
          this.alert = true
          this.alertcolor = 'danger'
          this.text =
            'Beim Speichern des Team ist ein Fehler aufgetreten: \n' + error
          this.saving = false
        })
    },
    updateAbteilung() {
      this.saving = true
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.team.id)
        .update({
          abteilung: this.abteilung,
        })
        .then((doc) => {
          this.alert = true
          this.alertcolor = 'success'
          this.text = 'Team wurde erfolgreich gespeichert!'
          this.saving = false
        })
        .catch((error) => {
          this.alert = true
          this.alertcolor = 'danger'
          this.text =
            'Beim Speichern des Team ist ein Fehler aufgetreten: \n' + error
          this.saving = false
        })
    },
    del() {
      this.start_delete = true
    },
    async deleteTeam() {
      this.deleting = true
      if (this.del_veranstaltungen) {
        await firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Veranstaltung')
          .where('mannschaft', '==', this.team.id)
          .get()
          .then((snap) => {
            snap.forEach((doc) => {
              firebase
                .firestore()
                .collection('User')
                .doc(this.user.data.uid)
                .collection('Veranstaltung')
                .doc(doc.id)
                .delete()
            })
          })
      }
      await firebase
        .firestore()
        .collection('User')
        .where('verein.id', '==', this.user.data.uid)
        .where('team', 'array-contains', this.team.id)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            firebase
              .firestore()
              .collection('User')
              .doc(doc.id)
              .update({
                team: firebase.firestore.FieldValue.arrayRemove(this.team.id),
              })
          })
        })
        .then(() => {
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Team')
            .doc(this.team.id)
            .delete()
            .then(() => {
              router.push('/teams')
            })
            .catch((error) => {
              this.deleting = false
              this.alert = true
              this.alertcolor = 'danger'
              this.text =
                'Beim Löschen des Team ist ein Fehler aufgetreten: \n' + error
            })
        })
    },
    async changeTeamname() {
      this.changing = true
      await firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.teamname)
        .get()
        .then(async (docRef) => {
          if (!docRef.exists) {
            await firebase
              .firestore()
              .collection('User')
              .doc(this.user.data.uid)
              .collection('Veranstaltung')
              .where('mannschaft', '==', this.team.id)
              .get()
              .then((snap) => {
                snap.forEach((doc) => {
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(this.user.data.uid)
                    .collection('Veranstaltung')
                    .doc(doc.id)
                    .update({
                      mannschaft: this.teamname,
                    })
                })
              })
            await firebase
              .firestore()
              .collection('User')
              .where('verein.id', '==', this.user.data.uid)
              .where('team', 'array-contains', this.team.id)
              .get()
              .then((snap) => {
                snap.forEach((doc) => {
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(doc.id)
                    .update({
                      team: firebase.firestore.FieldValue.arrayUnion(
                        this.teamname,
                      ),
                    })
                })
              })

            await firebase
              .firestore()
              .collection('User')
              .where('verein.id', '==', this.user.data.uid)
              .where('team', 'array-contains', this.team.id)
              .get()
              .then((snap) => {
                snap.forEach((doc) => {
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(doc.id)
                    .update({
                      team: firebase.firestore.FieldValue.arrayRemove(
                        this.team.id,
                      ),
                    })
                })
              })
            var old_name = this.team.id
            firebase
              .firestore()
              .collection('User')
              .doc(this.user.data.uid)
              .collection('Team')
              .doc(this.teamname)
              .set({
                name: this.teamname,
                verein: this.team.data.verein,
                abteilung: this.team.data.abteilung,
              })
              .then(async () => {
                await firebase
                  .firestore()
                  .collection('User')
                  .doc(this.user.data.uid)
                  .collection('Team')
                  .doc(old_name)
                  .collection('Mitglieder')
                  .get()
                  .then((snap) => {
                    snap.forEach((member) => {
                      firebase
                        .firestore()
                        .collection('User')
                        .doc(this.user.data.uid)
                        .collection('Team')
                        .doc(this.teamname)
                        .collection('Mitglieder')
                        .add(member.data())
                    })
                  })
                await firebase
                  .firestore()
                  .collection('User')
                  .doc(this.user.data.uid)
                  .collection('Team')
                  .doc(old_name)
                  .delete()
                  .then(() => {
                    router.push('/teams')
                  })
              })
          } else {
            this.changing = false
            this.teamname = this.team.id
            //ERROR-HANDLING
          }
        })
    },
  },
}
</script>
