<template>
  <v-card
    :class="addclass ? addclass : 'rounded-xl my-4'"
    :height="height"
    :elevation="elevate ? elevate : 8"
    :color="bgcolor"
    :style="`color: ${textcolor};`"
    :loading="loading"
    :disabled="disabled"
    :flat="flat"
    link
    :to="to"
    @click="$emit('click')"
  >
    <v-row
      justify="center"
      class="px-2 h-100"
      :class="twoline ? 'text-center' : ''"
    >
      <v-col cols="2" align-self="center" class="text-right" v-if="chevleft">
        <v-icon large>mdi-chevron-left</v-icon>
      </v-col>
      <v-col
        :cols="twoline ? 12 : 2"
        align-self="center"
        :class="twoline ? 'mb-0 pb-1' : ''"
        v-if="vereinslogo || icon"
      >
        <v-avatar class="my-auto" :large="twoline">
          <v-img
            contain
            v-if="vereinslogo"
            :src="vereinslogo"
            :style="`background-color: ${textcolor};`"
          ></v-img>
          <v-icon
            v-if="icon"
            :style="`background-color: ${textcolor}; color: ${bgcolor};`"
          >
            {{ icon }}
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col
        :cols="twoline ? 12 : chev || chevleft ? 8 : 10"
        align-self="center"
        :class="
          twoline ? 'mt-0 pt-1' : icon || vereinslogo ? '' : 'text-center'
        "
      >
        <span
          class="font-weight-bold text-truncate d-block"
          :class="
            !chev && verein
              ? 'text-uppercace text-left '
              : upper
              ? 'text-uppercase '
              : ''
          "
          v-for="(block, index) in titel(title)"
          :key="index"
        >
          {{ block }}
        </span>
        <small v-if="verein" class="text-uppercase">
          {{ verein }}
        </small>
        <small v-if="ggg" class="text-uppercase">
          <v-icon :color="textcolor">
            mdi-shield-check
          </v-icon>
          {{ ggg }}
        </small>
      </v-col>
      <v-col cols="2" align-self="center" class="text-right" v-if="chev">
        <v-icon large :color="textcolor">mdi-chevron-right</v-icon>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'buttoncard',
  props: {
    addclass: String,
    title: String,
    icon: String,
    textcolor: String,
    bgcolor: String,
    chev: Boolean,
    chevleft: Boolean,
    upper: Boolean,
    vereinslogo: String,
    verein: String,
    to: String,
    twoline: Boolean,
    loading: Boolean,
    flat: Boolean,
    disabled: Boolean,
    elevate: Number,
    height: String,
    ggg: String,
  },
  methods: {
    titel(text) {
      var blocks = text.split('<br>')
      return blocks
    },
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
  },
}
</script>
